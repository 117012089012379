import { useRouter } from "next/router"
import { NextSeo } from "next-seo"

import Gtm from "lib/gtm"
import { useTranslation } from "lib/i18n/translation"

import { ProfilePage } from "components/common"

const Profile = () => {
	const { c } = useTranslation("common")
	const router = useRouter()
	const url = router ? router.asPath : null

	return (
		<>
			<Gtm pageType="Mon profil" pagePath={url} pageTitle={c("profile")}/>
			<NextSeo
				title={c("my-cinetek")}
			/>
			<ProfilePage />
		</>
	)
}

export default Profile
