import { useRouter } from "next/router"
import { useCountry } from "lib/country"

const defaultSlugBuilder = (slug, countrySlug) => {
	return Promise.resolve([
		`/[lang]/${slug}?lang=${countrySlug}`,
		`/${countrySlug}/${slug}`
	])
}

const useHandleLinkFromContent = (options = {}) => {
	const router = useRouter()
	const { countrySlug } = useCountry()
	const slugBuilder = options.slugBuilder || defaultSlugBuilder

	return (event) => {
		event.preventDefault()

		let newTarget = event.target

		if (newTarget.tagName !== "A") {
			newTarget = newTarget.closest("A")
		}

		if (!newTarget?.href) {
			return
		}

		let slug = newTarget.href.split("/")
		slug = slug[slug.length - 1].replace(".html", "")

		slugBuilder(slug, countrySlug)
			.then((slugs) => {
				if (!slugs) {
					return
				}

				const [url, asUrl] = slugs

				if (!url) {
					return
				}

				router.push(url, asUrl)
			})
	}
}

export default useHandleLinkFromContent
