import { useRouter } from "next/router"

import { useTranslation } from "lib/i18n/translation"
import SkeletonLink from "components/common/skeleton-link"
import Gtm from "lib/gtm"

import Image from "components/common/image"

const CustomError = ({ statusCode }) => {

	const { c } = useTranslation("common")

	let message = ""

	switch(statusCode) {
		case "404":
			message = "page-not-found"
			break
		default:
			message: "error-occured"
			break
	}

  return <Error statusCode={statusCode} message={message} c={c} />
}

const Error = ({ statusCode, message, c }) => {
	const router = useRouter()
	const url = router ? router.asPath : null

	return(
	<>
		{ statusCode == 404 && (
			<Gtm pageType="Page 404" pagePath={url} pageTitle="page404" page404="page404" />
		) }
		<section className="centered">
			<div className="oups">
				<div className="infos">
					<h1>{statusCode ? <strong>#{statusCode}</strong> : null}<br />{c(message)}</h1>
				</div>
				<figure>
					<picture>
						<Image src="/static/images/tv.png" alt={statusCode} style={{ background: "url(/static/images/noise.gif)" }} />
					</picture>
				</figure>
			</div>
			<p className="introduction">{c("sorry-for-the-interruption")}</p>
			<p className="section-link">
				<SkeletonLink href="/">
					<a title={c("back-homepage")} className="icon-left button">{c("back-homepage")}</a>
				</SkeletonLink>
			</p>
		</section>
	</>
	)
}

function getInitialProps({ res, err }) {
  let statusCode;
  // If the res variable is defined it means nextjs
  // is in server side
  if (res) {
    statusCode = res.statusCode;
  } else if (err) {
    // if there is any error in the app it should
    // return the status code from here
    statusCode = err.statusCode;
  } else {
    // Something really bad/weird happen and status code
    // cannot be determined.
    statusCode = null;
  }
  return { statusCode };
}

CustomError.getInitialProps = getInitialProps;

export default CustomError;
