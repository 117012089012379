import { useRouter } from "next/router"

import { Query } from "components/common"
import Contribute from "components/modals/contribute"

import { useTranslation } from "lib/i18n/translation"
import { FETCH_PAGE_BY_ID } from "lib/graphql/queries"
import { useModal } from "lib/modal"
import { useMainCategories } from "lib/tools/categories"
import { useCountry } from "lib/country"
import Gtm from "lib/gtm"
import { sortLists } from "lib/tools/categories"

const About = ({}) => {
	const { show } = useModal()
	const { c } = useTranslation("common")
	const { lists } = useMainCategories()
	const { country: { total_films, total_unpublished_films } } = useCountry()
	const router = useRouter()
	const url = router ? router.asPath : null

	const showContribute = () => {
		show({
			id: "contribute",
			children: <Contribute />,
			className: "modal short"
		})
	}

	let allLists = sortLists(lists)

	return (
		<>
			<Gtm pageType="À propos" pagePath={url} pageTitle={c("about")}/>
			<section className="_black">
				<h1 className="main-title">{c("lacinetek")}</h1>
				<p className="excerpt" dangerouslySetInnerHTML={{ __html: c("about-lacinetek-excerpt") }} />
				<div className="columnizer" data-columns="3">
					<p>{c("about-lacinetek-intro-1", { total_films, total_unpublished_films })}</p>
				</div>
			</section>
			<a href="#" onClick={e => { e.preventDefault(); }} rel="nofollow noopener" className="anchor" id="offres" />
			<a href="#" onClick={e => { e.preventDefault(); }} rel="nofollow noopener" className="anchor" id="histoire" />
			<section>
				<h2 className="section-title icon-comment">{c("about-our-history")}</h2>
				<p className="excerpt">{c("about-why")}</p>
				<div className="columnizer" data-columns="3" dangerouslySetInnerHTML={{ __html: c("about-intro") }} />
			</section>
			<a href="#" onClick={e => { e.preventDefault(); }} rel="nofollow noopener" className="anchor" id="statuts" />
			<section>
				<h2 className="section-title icon-search">{c("about-what")}</h2>
				<p className="excerpt">{c("about-cinematheque")}</p>
				<div className="columnizer" data-columns="3" dangerouslySetInnerHTML={{ __html: c("about-cinematheque-desc") }} />
			</section>
			<section>
				<h2 className="section-title icon-key">{c("about-prescription")}</h2>
				<p className="excerpt">{c("about-prescription-intro", { directors_count: allLists.length })}</p>
				<div className="columnizer" data-columns="3" dangerouslySetInnerHTML={{ __html: c("about-prescription-desc", { directors: allLists.map(l => l.name.replace(' | ', ' ')).join(', ')})}} />
			</section>
			<a href="#" onClick={e => { e.preventDefault(); }} rel="nofollow noopener" className="anchor" id="regles" />
			<section>
				<h2 className="section-title icon-book">{c("about-rules")}</h2>
				<p className="excerpt">{c("about-rules-intro")}</p>
				<div className="columnizer" data-columns="3" dangerouslySetInnerHTML={{ __html: c("about-rules-desc")}} />
				<p className="centered"><a href="#" onClick={e => { e.preventDefault(); showContribute() }} rel="nofollow noopener" title={c("about-contribute")} data-overlay="#overlay-contribute" className="button">{c("about-contribute")}</a></p>
			</section>
			<a href="#" onClick={e => { e.preventDefault(); }} rel="nofollow noopener" className="anchor" id="equipe" />
			<Query
				query={FETCH_PAGE_BY_ID}
				options={{variables: { id: 18 }}}
			>
				{({cms: { pages: { items: [{content: team}]}}}) => (
					<section className="about-team-section" dangerouslySetInnerHTML={{ __html: `
						<h2 className="section-title icon-user"><strong>${c("the-team")}</strong></h2>
						${team}
					`}} />
				)}
			</Query>
		</>
	)
}

export default About
