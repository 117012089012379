const Contribute = ({}) => (
	<>
		<h2 className="overlay-title">Contribuez</h2>
		<p>Ce site est le nôtre et le vôtre. Aidez-nous à l'améliorer.</p>
		<p>Par exemple, en nous signalant des erreurs qui auraient échappé à notre vigilance, en nous donnant des informations pour remonter jusqu'aux ayants-droits des films "introuvables", en nous indiquant des "Bonus Archive" que vous connaitriez et que vous souhaiteriez voir figurer sur le site.</p>
		<p>Votre aide nous est précieuse.</p>
		<p>Vous pouvez nous écrire à <a href="mailto:lacinetek@lacinetek.com" title="Envoyer un mail">lacinetek@lacinetek.com</a></p>
	</>
)

export default Contribute
