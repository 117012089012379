import { useState } from "react"
import { useRouter } from "next/router"

import { Query, Slider } from "components/common"
import SkeletonLink from "components/common/skeleton-link"
import Image from "components/common/image"
import { baseTreasure } from "components/common/skeleton"

import Gtm from "lib/gtm"
import { useTranslation } from "lib/i18n/translation"
import { get, replace } from "lib/redirects"
import { FETCH_TREASURES } from "lib/graphql/queries"
import { TREASURES } from "lib/tools/categories"

import image, { CATEGORY_LOGO_LARGE, CATEGORY_TREASURE } from "lacinetek-common/src/images"
import { metadata, FEATURE_QUOTE } from "lacinetek-common/src/metadata"

const Treasures = ({t}) => {
	const baseTreasures = [
		{id: 1, ...baseTreasure},
		{id: 2, ...baseTreasure},
		{id: 3, ...baseTreasure},
		{id: 4, ...baseTreasure},
		{id: 5, ...baseTreasure},
		{id: 6, ...baseTreasure}
	]

	return (
		<Query
			query={FETCH_TREASURES}
			options={{variables: { id: process.env.CATEGORIES.find(c => c.key == TREASURES).id}}}
			renderLoading={() => (
				<Content
					mainCategory={{descriptionShort: "Lorem ipsum", metadata: [], description: "Lorem ipsum dolor sit amet"}}
					treasures={baseTreasures}
				/>
			)}
		>
			{ ({cms: { categories: { items: [treasures] } } }) => (
				<Content
					mainCategory={treasures}
					treasures={treasures.children.items}
				/>
			)}
		</Query>
	)
}

const Content = ({mainCategory, treasures}) => {
	const { c } = useTranslation("common")
	const [currentHovered, setCurrentHovered] = useState(0)

	const toggleCurrentHovered = (index) => {
		if (index !== currentHovered) {
			setCurrentHovered(index)
		}
	}

	const router = useRouter()
	const url = router ? router.asPath : null

	return (
		<>
			<Gtm pageType="Trésors cachés" pagePath={url} pageTitle={c("treasures")} />
			<section className="focus has-splitted black">
				<TreasureHeader
					mainCategory={mainCategory}
					treasures={treasures}
					currentHovered={currentHovered}
				/>
				<TreasureSlider
					treasures={treasures}
					toggleCurrentHovered={toggleCurrentHovered}
					currentHovered={currentHovered}
					loading={false}
				/>
			</section>
		</>
	)
}

const TreasureHeader = ({mainCategory, treasures, currentHovered}) => {
	const { c } = useTranslation("common")

	return (
		<>
			<div className="columns">
				<aside className="Image">
					<ul id="tabs-institutions" className="tabs-institutions tabs">
						{ treasures.map((treasure, index) => (
								<li className={`tab ${currentHovered == index ? "active" : ""}`}>
									<figure>
										<picture>
											<Image src={image(treasure, CATEGORY_TREASURE)} alt={treasure.name} />
										</picture>
									</figure>
								</li>
						))}
					</ul>
				</aside>
				<div className="content">
					<h1 className="main-title">{c("treasures-strong")}</h1>
					<p><span className="introduction">{mainCategory.descriptionShort}</span></p>
					<blockquote className="large">{metadata(mainCategory, FEATURE_QUOTE)}</blockquote>
					<div className="columnizer" data-columns="2" dangerouslySetInnerHTML={{ __html: mainCategory.description }} />
					<hr className="blank" />
					<p className="alert short">{c("treasures-alert-short")}</p>
				</div>
			</div>
		</>
	)
}

const Slide = ({ treasure, currentHovered, toggleCurrentHovered, loading, index }) => {
	const target = "/tresors-caches/[id]"
	const realTarget = get(target)

	return (
		<SkeletonLink
			href={target}
			as={replace(realTarget, treasure.linkRewrite)}
			loading={loading}
			className="slide-content"
			passHref
		>
			<a
				title={treasure.name}
				className={`slide-content${loading ? " skeleton" : ""}${index === currentHovered ? " active" : ""}`}
				onMouseEnter={() => {
					toggleCurrentHovered(index)
				}}
			>
				<div className="slide-content">
					<figure>
						<picture>
							<Image
								src={image(treasure, CATEGORY_LOGO_LARGE)}
								className={loading ? "skeleton" : ""}
								alt={treasure.name}
								width={280}
								height={155}
							/>
						</picture>
					</figure>
				</div>
			</a>
		</SkeletonLink>
	)
}

const TreasureSlider = ({ treasures, toggleCurrentHovered, currentHovered, loading }) => (
	<Slider
		id="treasures-slider"
		slides={treasures}
		slideComponent={Slide}
		slidePropKey="treasure"
		slideRelatedProps={{
			currentHovered,
			toggleCurrentHovered
		}}
		loading={loading}
		className="min institutions has-tabs"
		hidePagination
	/>
)

export default Treasures
