import Home from "."

const OfflinePlayer = (props) => {
	return <Home {...props} />
}

OfflinePlayer.getInitialProps = (ctx) => {
	const newCtx = {
		...ctx,
		query: {
			"desktop-player": "",
			...ctx.query
		}
	}

	return Home.getInitialProps(newCtx)
}

export default OfflinePlayer
