
import { useRouter } from "next/router"
import nextRedirects from "lib/redirects"
import Error from "pages/_error"
import dynamic from "next/dynamic"

import about from "pages/[lang]/about"
import news from "pages/[lang]/news"
import profile from "pages/[lang]/profile"
import treasures from "pages/[lang]/treasures"
import offlinePlayer from "pages/[lang]/offline-player"
import offer from "pages/[lang]/offer"
import selection from "pages/[lang]/selection"

const pages = {
	about,
	news,
	profile,
	treasures,
	offer,
	"offline-player": offlinePlayer,
	selection,
}

const debug = require("debug")("lacinetek:pages:slug")

export default function Slug(props) {
	const router = useRouter()

	if (!router) {
		return null
	}

	const redirect = findRedirect(router)

	if (redirect) {
		const page = redirect.from.slice(1)
		if (!pages[page]) {
			throw new Error("Page not defined")
		}

		const Component = pages[page]

		return <Component {...props} />
	}

	return <Error statusCode="404" message="page-not-found" />
}

Slug.getInitialProps = async (ctx) => {
	const redirect = findRedirect(ctx)

	if (!redirect) {
		return {}
	}

	const page = redirect.from.slice(1)
	if (!pages[page]) {
		throw new Error("Page not defined")
	}

	if (!pages[page].getInitialProps) {
		return {}
	}

	return pages[page].getInitialProps(ctx)
}

function findRedirect(ctx) {
	return nextRedirects.find(({ from, to, page }) => {
		if (`/${ctx.query.slug}` === to) {
			return true
		}

		return false
	})
}
