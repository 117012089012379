import { useQuery } from "@apollo/react-hooks"
import { useRouter } from "next/router"
import { CATEGORY_SELECTION, imagesOfType } from "lacinetek-common/src/images"

import { FETCH_SVOD_OFFERS } from "lib/graphql"
import Gtm from "lib/gtm"
import { useCart } from "lib/cart"
import useHandleLinkFromContent from "lib/content/link"
import { useTranslation } from "lib/i18n/translation"
import { useCountry } from "lib/country"

import SkeletonLink from "components/common/skeleton-link"
import Image from "components/common/image"
import CinepassBig from "static/assets/icons/cinepass_big.svg"
import ManyRolls from "static/assets/icons/many-rolls.svg"
import Tickets from "static/assets/icons/tickets.svg"

const Offer = ({ }) => {
	const { c } = useTranslation("common")
	const { addSVODToCart, addCinepassToCart } = useCart()
	const { country, svodAvailable } = useCountry()
	const handleLinkFromContent = useHandleLinkFromContent()
	const router = useRouter()
	const url = router ? router.asPath : null

	const { data, loading, error } = useQuery(FETCH_SVOD_OFFERS, {
		variables: {
			query: `nos-offres-${country.isoCode}`
		}
	})

	if (loading) {
		return (
			<div className="is-loading centered" />
		)
	}

	let bannerImg
	let lacinetekOffer

	if (data.cms.categories.items[0].children.items.length > 0) {
		const { children: { items: offers } } = data.cms.categories.items[0]
		lacinetekOffer = offers.find(offer => offer.name.match(/lacinetek/gi))
		bannerImg = lacinetekOffer ? imagesOfType(lacinetekOffer, CATEGORY_SELECTION)[0] : null
	}

	return (
		<>
			<Gtm pageType="Page offrir" pagePath={url} pageTitle={c("offer")} />
			{
				!!bannerImg
				&& <section className="focus full no-fx">
					<figure className="cover full">
						<picture>
							<Image
								src={bannerImg}
								alt={lacinetekOffer?.descriptionShort}
							/>
						</picture>
					</figure>
				</section>
			}
			<section className="red">
				<h1 className="main-title">{c("offer_cinetek-strong")}</h1>
				<div className="offers blocks">
					<div className="item full">
						<div className="item-content">
							<figure><CinepassBig title={c("pm_cinepass")} /></figure>
							<div className="infos">
								<h3 className="title">{c("offer-cinepass")}</h3>
								<p className="price" dangerouslySetInnerHTML={{ __html: c("offer-cinepass-price") }} />
							</div>
							<a
								href="#"
								onClick={e => { e.preventDefault(); addCinepassToCart({ offer: true }) }}
								rel="nofollow noopener" title={c("offer-this-cinepass")}
								className="button full"
							>
								{c("choose-some-cinepass")}
							</a>
							<div className="description">
								<p onClick={handleLinkFromContent} dangerouslySetInnerHTML={{ __html: c("offer-cinepass-description") }} />
							</div>
						</div>
					</div>
					{
						svodAvailable
						&& <>
							<div className="item full">
								<div className="item-content">
									<figure><ManyRolls title={c("annual-subscription-icon-title")} /></figure>
									<div className="infos">
										<h3 className="title" dangerouslySetInnerHTML={{ __html: c("offer-gift-infos-selection") }}></h3>
										<p className="price" dangerouslySetInnerHTML={{ __html: c("offer-subscription-price") }}></p>
									</div>
									<a href="#"
										onClick={e => { e.preventDefault(); addSVODToCart({ offer: true }) }}
										rel="nofollow noopener" title={c("offer-this-subscription")}
										className="button full"
									>
										{c("offer-gift-infos")}
									</a>
									<div className="description">
										<p onClick={handleLinkFromContent} dangerouslySetInnerHTML={{ __html: c("offer-subscription-description") }} />
									</div>
								</div>
							</div>
						</>
					}
					<div className="item full">
						<div className="item-content">
							<figure><Tickets title={c("tickets-icon-title")} /></figure>
							<div className="infos">
								<h3 className="title">{c("offer-film")}</h3>
								<p className="price" dangerouslySetInnerHTML={{ __html: c("offer-film-price") }}></p>
							</div>
							<SkeletonLink href="/films">
								<a
									title={c("see-catalog")}
									className="button full"
								>
									{c("see-catalog")}
								</a>
							</SkeletonLink>
							<div className="description">
								<p onClick={handleLinkFromContent} dangerouslySetInnerHTML={{ __html: c("offer-film-description") }} />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Offer
